import React, {Fragment, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Toolbar,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import logo from '../assets/logo.png'

const nav = [
  {
    label: 'Careers',
    href: 'https://the-lifted-initiative.breezy.hr/',
  },
]

const NavItem = ({anchor, children, label, href, route}, index) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [id] = useState(`menu-item-${label.toLowerCase().replace(/ /g, '')}`)

  return (
    <Box
      onMouseOver={() => {
        setOpen(true)
      }}
      onMouseOut={() => {
        setOpen(false)
      }}
    >
      <Button
        id={id}
        variant="nav"
        className={open ? 'open' : null}
        sx={{
          position: 'relative',
          my: 2,
          display: 'block',
          '&.open': {background: 'rgba(0, 0, 0, 0.04)'},
        }}
        href={href}
        onClick={() => {
          if (children) return
          setOpen(false)
          if (route) navigate(route)
          if (anchor) navigate(`/${anchor}`)
        }}
      >
        {label}
      </Button>

      {children ? (
        <Box
          sx={{
            visibility: open ? 'visible' : 'hidden',
            opacity: open ? 1 : 0,
            transition: 'opacity 300ms',
            position: 'absolute',
            top: 58,
            zIndex: 5,
            minWidth: 200,
            background: '#fff',
            boxShadow: '0 5px 10px rgb(0 0 0 / 10%)',
            borderRadius: 2,
          }}
        >
          {children.map((child, childIndex) => {
            return (
              <MenuItem
                key={`menu-item-${index}-${childIndex}`}
                sx={{
                  '&:first-of-type': {
                    borderTopRightRadius: 6,
                    borderTopLeftRadius: 6,
                  },
                  p: 0,
                }}
              >
                <ListItemButton
                  href={child.href}
                  onClick={() => {
                    setOpen(false)
                    if (child.route) navigate(child.route)
                    if (child.anchor) navigate(`/${child.anchor}`)
                  }}
                  sx={{
                    '&:hover': {
                      background: 'none',
                    },
                    // p: 1.7,
                  }}
                >
                  {child.label}
                </ListItemButton>
              </MenuItem>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default function Header() {
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  return (
    <>
      <div id="back-to-top-anchor" />
      <Box
        sx={{position: 'sticky', top: 0, zIndex: 4}}
        className="page page--landing"
      >
        <AppBar
          position="static"
          sx={{background: '#fff', boxShadow: 'none', color: '#333'}}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                component="img"
                sx={{
                  display: {xs: 'none', md: 'flex'},
                  height: 40,
                  mr: 4,
                  cursor: 'pointer',
                }}
                alt="Lifted Labs"
                src={logo}
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
              />

              <Box
                sx={{
                  flex: '30%',
                  justifyContent: 'flex-start',
                  display: {xs: 'flex', md: 'none'},
                }}
              >
                <IconButton
                  size="large"
                  onClick={() => setDrawerOpen(true)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: {xs: 'flex', md: 'none'},
                  flex: '30%',
                  justifyContent: 'center',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 40,
                    cursor: 'pointer',
                  }}
                  alt="Lifted Labs"
                  src={logo}
                  onClick={() => {
                    navigate('/')
                    window.scrollTo(0, 0)
                  }}
                />
              </Box>

              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                {nav.map((item, index) => {
                  return <NavItem key={`menu-item-${index}`} {...item} />
                })}
              </Box>

              <Box
                sx={{display: 'flex', flex: '30%', justifyContent: 'flex-end'}}
              >
                <Button
                  href="https://forms.gle/qAErJ4L83yLnMkKS6"
                  variant="secondary"
                  sx={{
                    my: 2,
                    display: {xs: 'none', sm: 'block'},
                    whiteSpace: 'nowrap',
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List sx={{width: 250}}>
          {nav.map(({anchor, children, label, href, route}, index) => {
            return (
              <Fragment key={`mobile-menu-item-${index}`}>
                <ListItem disablePadding sx={{borderBottom: '1px solid #ddd'}}>
                  <ListItemButton
                    href={href}
                    onClick={() => {
                      if (children) return
                      setDrawerOpen(false)
                      if (route) navigate(route)
                      if (anchor) navigate(`/${anchor}`)
                    }}
                    sx={{
                      '&:hover': {
                        background: children ? '#fff' : '#f1f1f1',
                        cursor: children ? 'default' : 'pointer',
                      },
                    }}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
                {children ? (
                  <Box sx={{background: '#fff'}}>
                    {children.map((child, childIndex) => {
                      return (
                        <ListItem
                          disablePadding
                          sx={{borderBottom: '1px solid #ddd', pl: 2}}
                          key={`mobile-menu-item-${index}-${childIndex}`}
                        >
                          <ListItemButton
                            href={child.href}
                            onClick={() => {
                              setDrawerOpen(false)
                              if (child.route) navigate(child.route)
                              if (child.anchor) navigate(`/${child.anchor}`)
                            }}
                          >
                            <ListItemText primary={child.label} />
                          </ListItemButton>
                        </ListItem>
                      )
                    })}
                  </Box>
                ) : null}
              </Fragment>
            )
          })}
          <ListItem
            disablePadding
            sx={{borderBottom: '1px solid #ddd'}}
            key={`mobile-menu-item-last`}
          >
            <ListItemButton href="https://forms.gle/qAErJ4L83yLnMkKS6">
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}
