import {createTheme} from '@mui/material/styles'

const colors = {
  primary: '#915cd6',
  secondary: '#5ac3b7',
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.primary,
      light: '#cebaeb',
      dark: '#594770',
    },
    secondary: {
      contrastText: '#fff',
      main: colors.secondary,
      light: '#8ddfd4',
      dark: '#69a8a0',
    },
  },
  typography: {
    fontFamily: ['Roboto Flex', 'sans-serif'].join(','),
    p: {
      fontSize: '1.25rem',
      margin: '0 0 1em',
    },
    h1: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: '900',
      textTransform: 'uppercase',
      color: '#fff',
    },
    h2: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: '900',
      textTransform: 'uppercase',
    },
    h3: {
      // fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: '900',
      fontSize: '2.125rem',
      // textTransform: 'uppercase',
      color: '#333',
      margin: '0 0 1em',
    },
    h4: {
      // fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: '900',
      fontSize: '1.5rem',
      // textTransform: 'uppercase',
      color: '#333',
      margin: '0 0 1em',
    },
    h5: {
      // fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: '900',
      fontSize: '1.25rem',
      // textTransform: 'uppercase',
      color: '#333',
      margin: '0 0 1em',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          borderRadius: 20,
        },
      },
      variants: [
        {
          props: {variant: 'primary'},
          style: {
            padding: '5px 15px',
            background: colors.primary,
            color: '#fff',
            // textTransform: 'none',
            fontWeight: 'bold',
            fontSize: 14,
            '&:hover': {
              background: colors.primary,
            },
          },
        },
        {
          props: {variant: 'secondary'},
          style: {
            padding: '5px 15px',
            background: colors.secondary,
            color: '#fff',
            // textTransform: 'none',
            fontWeight: 'bold',
            fontSize: 14,
            '&:hover': {
              background: colors.secondary,
            },
          },
        },
        {
          props: {variant: 'nav'},
          style: {
            background: 'none',
            padding: '5px 15px',
            textTransform: 'none',
          },
        },
      ],
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
  },
})

export default theme
