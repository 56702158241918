import React, {useEffect, useState} from 'react'
// import {useNavigate} from 'react-router-dom'

import {Box, Button, Container, Grid, Typography} from '@mui/material'

import Hero from '../components/Hero'

import imgLogo2 from '../assets/logo2.png'
import imgLogo3 from '../assets/logo3.png'

const randomNumber = (min, max) => Math.random() * (max - min) + min

export default function Landing() {
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (init) return

    const cubeElements = document.querySelectorAll('.cube')
    const arr = ['x', 'y']

    cubeElements.forEach((el) => {
      setTimeout(() => {
        el.classList.add(`float-${arr[Math.floor(Math.random() * arr.length)]}`)
      }, randomNumber(1000, 3000))
    })

    setInit(true)
  }, [init])

  return (
    <>
      <Hero color={0x342a3d}>
        <Box
          component="img"
          sx={{mt: 4, display: 'block', mx: 'auto'}}
          alt="Lifted Labs"
          src={imgLogo2}
          className="fade_in float-y"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />
        <Box
          component="img"
          sx={{display: 'block', mx: 'auto', mt: 3, mb: 6, maxWidth: 200}}
          alt="Lifted Labs"
          src={imgLogo3}
          className="fade_in"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />
        <Typography
          variant="h1"
          sx={{
            mb: 0,
            fontSize: {xs: '1.25rem', sm: '1.875rem', md: '3.125rem'},
          }}
        >
          Web3 Builders
          <br /> For Public Good
        </Typography>
      </Hero>

      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            pt: 10,
            pb: 5,
            px: 3,
            width: {xs: '100%', sm: '70%', md: '50%', lg: '40%', xl: '30%'},
            margin: 'auto',
          }}
        >
          <Grid container spacing={5} wrap="wrap">
            <Grid item xs={12}>
              <Typography component="p" variant="p">
                We are a Web3 development company building innovative solutions
                and products for startups, DAOs, and enterprises.
              </Typography>
              <Typography component="p" variant="p">
                We primarily build on the{' '}
                <a href="https://www.liftedinit.org">Manifest Network</a>, but
                also support other L1 and L2 blockchains. Please contact us if
                you have a Web3 project idea or business need. From
                infrastructure to marketplaces to dApps, Lifted Labs has you
                covered.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            width: {xs: '100%', sm: '70%', md: '50%', lg: '40%', xl: '30%'},
            margin: 'auto',
            mb: 20,
            px: 3,
          }}
        >
          <Box
            sx={{
              py: 5,
              px: 3,
              margin: 'auto',
              background: '#eee',
              borderRadius: 5,
            }}
          >
            <Grid container spacing={5} wrap="wrap">
              <Grid item xs={12}>
                <Typography
                  component="p"
                  variant="p"
                  align="center"
                  sx={{fontWeight: 'bold'}}
                >
                  Tell us about your Web3 project and we'll be in touch.
                </Typography>
                <Grid item xs={12}>
                  <Button
                    href="https://forms.gle/qAErJ4L83yLnMkKS6"
                    target="_blank"
                    variant="primary"
                    sx={{
                      display: 'block',
                      mt: 4,
                      mx: 'auto',
                      width: 220,
                      textAlign: 'center',
                    }}
                  >
                    Free Consultation
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  )
}
