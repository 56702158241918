import React from 'react'
import {
  Box,
  Fab,
  Fade,
  Grid,
  Link,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import manifestPowered from '../assets/manifest-powered.webp'

function ScrollTop(props) {
  const {children} = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    )

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      })
    }
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{position: 'fixed', bottom: 16, right: 16}}
      >
        {children}
      </Box>
    </Fade>
  )
}

export default function Footer(props) {
  return (
    <Box component="footer" sx={{mt: 5, py: 5, background: '#eee'}}>
      <Grid container spacing={1} wrap="wrap">
        <Grid item xs={12}>
          <Link
            component={RouterLink}
            to="https://www.liftedinit.org/#/hosting"
            underline="none"
            sx={{display: 'block', my: 3, mx: 'auto', width: 200}}
          >
            <Box
              component="img"
              src={manifestPowered}
              style={{maxWidth: '100%'}}
              alt="Powered by The Manifest Network"
              sx={{boxShadow: '0 1px 9px rgba(0, 0, 0, 0.1)'}}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="p"
            variant="p"
            align="center"
            sx={{fontSize: 12}}
          >
            &copy; {new Date().getUTCFullYear()} Lifted Labs
          </Typography>
        </Grid>
      </Grid>

      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{background: '#000'}}
        >
          <KeyboardArrowUpIcon sx={{color: '#fff'}} />
        </Fab>
      </ScrollTop>
    </Box>
  )
}
